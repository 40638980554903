const apiPaths = {
  albums: '/users/:userId/albums', //get, post
  subAlbums: '/users/:userId/albums/:albumId/albums', //get
  sharedAlbums: '/users/:userId/shared_items/query',
  album: '/users/:userId/albums/:id', //get, put, delete
  albumFilesSinceCount: '/users/:userId/albums/:id/files_since_count', //get
  disableShareToken: '/users/:userId/albums/:id/disable_share_token', //post
  resetShareToken: '/users/:userId/albums/:id/reset_share_token', //post
  moveAlbums: '/users/:userId/albums/move', //post
  destroyAlbums: '/users/:userId/albums/destroy_multiple', //post
  removeVisited: '/users/:userId/shared_items/destroy_multiple', //post
  metrics: '/users/:userId/albums/:id/metrics',
  albumCopy: '/users/:userId/albums/:id/copy'
};

export function useAlbumApi() {
  return {
    async getAlbums({
      cursor,
      search,
      parentAlbumId,
      privacy,
      privacyFilter,
      shareToken,
      userId = useSessionStore().currentUserId,
      root = false, //note: this param is included in the query string and will only be respected by the API if search is not empty
      order
    }) {

      const query = {
        cursor,
        search,
        privacy,
        privacy_filter: privacyFilter,
        order,
        share_token: shareToken
      };

      if (root) {
        query.root = root;
      }

      const params = {
        route: {
          userId,
          albumId: parentAlbumId,
          shareToken
        },
        query
      };

      const apiPath = parentAlbumId ? apiPaths.subAlbums : apiPaths.albums;
      const data = await useMarsApiV3().fetch(apiPath, {params});
      data.albums = data.albums.map(albumMapper);

      return data;
    },

    async getSharedAlbums({cursor, order}) {
      const data = await useMarsApiV4().post(apiPaths.sharedAlbums, {
        params: {
          route: {
            userId: useSessionStore().currentUserId
          }
        },
        body: {
          cursor,
          filter: {
            order
          }
        }
      });

      data.albums = data.shared_items.map(sharedAlbumMapper);

      return data;
    },

    async getAlbum({id, userId = useSessionStore().currentUserId, shareToken}) {
      const data = await useMarsApiV3().fetch(apiPaths.album, {
        params: {
          route: {userId, id},
          query: {share_token: shareToken}
        }
      });

      return albumMapper(data.album);
    },

    async getAlbumFilesAddedSince({id, userId = useSessionStore().currentUserId, addedSince}) {
      const params = {
        route: {
          userId,
          id
        },
        query: {
          since: addedSince
        }
      };

      const data = await useMarsApiV3().fetch(apiPaths.albumFilesSinceCount, {params});
      return data.count;
    },

    async createAlbum(album) {
      const payload = Object.assign({}, album);

      if (!payload.privacy) {
        payload.privacy = ALBUM_PERMISSION_LAYERS.private.key;
      }

      const data = await useMarsApiV3().post(apiPaths.albums, {
        params: {
          route: {userId: useSessionStore().currentUserId}
        },
        body: payload
      });

      return albumMapper(data.album);
    },

    async updateAlbum({albumId, payload}) {
      const data = await useMarsApiV3().put(apiPaths.album, {
        params: {
          route: {
            userId: useSessionStore().currentUserId,
            id: albumId
          }
        },
        body: payload
      });

      return albumMapper(data.album);
    },

    destroyAlbums({ids}) {
      return useMarsApiV3().post(apiPaths.destroyAlbums, {
        params: {route: {userId: useSessionStore().currentUserId}},
        body: {ids}
      });
    },

    removeVisitedAlbums({ids}) {
      return useMarsApiV3().post(apiPaths.removeVisited, {
        params: {route: {userId: useSessionStore().currentUserId}},
        body: {ids}
      });
    },

    disableShareToken({id}) {
      return useMarsApiV3().post(apiPaths.disableShareToken, {
        params: {
          route: {
            id,
            userId: useSessionStore().currentUserId
          }
        }
      });
    },

    async resetShareToken({id}) {
      const data = await useMarsApiV3().post(apiPaths.resetShareToken, {
        params: {
          route: {
            id,
            userId: useSessionStore().currentUserId
          }
        }
      });


      return data.share_token;
    },

    moveAlbums({albumIds, parentId}) {
      return useMarsApiV3().post(apiPaths.moveAlbums, {
        params: {
          route: {
            userId: useSessionStore().currentUserId
          }
        },
        body: {
          album_ids: albumIds,
          parent_id: parentId
        }
      });
    },

    async getAlbumMetrics({id, userId, shareToken}) {
      const data = await useMarsApiV3().fetch(apiPaths.metrics, {
        params: {
          route: {id, userId},
          query: {share_token: shareToken}
        }
      });

      return data.metrics;
    },

    async copyAlbum({album, copyTags}) {
      const data = await useMarsApiV3().post(apiPaths.albumCopy, {
        params:{
          route: {
            id: album.id,
            userId: album.user.id
          }
        },
        body: {
          copy_tags: copyTags
        }
      });

      return albumMapper(data.album);
    }
  };
}
